@font-face {
    font-family: "Typo Grotesk Bold";
    src: url("./assets/fonts/typo_grotesk_bold_demo.otf");
}

@font-face {
    font-family: "Typo Grotesk";
    src: url("./assets/fonts/typo_grotesk_demo.otf");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap");

:root {
    --primary: #004e96;
    --main-bg-color: #ecf0f3;
}

html {
    min-height: 100%;
}

body {
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--main-bg-color);
    font-family: "Typo Grotesk";
    position: relative;
}

.area-form {
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    background: #ecf0f3;
    box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
    padding: 2rem;

    button {
        margin-top: 1rem;
        width: 100%;
        border-radius: 50px;
    }
}

.MuiOutlinedInput-root {
    background: var(--main-bg-color);
    padding: 10px;
    padding-left: 20px;
    height: 50px;
    font-size: 14px;
    border-radius: 50px !important;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

.overlay {
    z-index: 100;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-gif {
        width: 5rem;
    }
}
