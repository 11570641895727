.modify-password-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.modify-password-field {
    margin-top: 1rem;
}

.MuiTextField-root {
    width: 100%;
}

.modify-password-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.first-field{
    margin-top: 0;
}

.modify-password-content h4{
    padding-left: 12px;
    padding-right: 12px;
}

.modify-password-field p{
    max-width: 220px;
    padding-left: 10px;
}