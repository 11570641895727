.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;
}

.modal-main {
    position:fixed;
    background: white;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display: flex;
    background-color: #F5F5F5;
    border: 3px solid #7c7c7c;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 2rem;
    margin: 2rem;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal-button-container{
    width: 340px;
    display: flex;
    justify-content: space-around;
}

.modal-button{
    width: 120px;
}

.home-button-area .reset-button{
    width: 100px;
    margin-right: 10px;
}