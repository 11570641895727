.login-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.login-field {
    margin-top: 1rem;
}

.MuiTextField-root {
    width: 100%;
}

.login-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
