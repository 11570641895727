.home-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
}

.home-area-form {
    margin: 0 0 0 1rem;
    max-width: 360px;
}

.area-form .MuiFormHelperText-contained {
    margin: 0;
    margin-top: 4px;
}

.home-field {
    margin-top: 1rem;
    display: flex;
    width: 100%;
}

.home-field .MuiInputLabel-outlined {
    transform: translate(14px, 10px) scale(1);
}

.home-field .MuiOutlinedInput-input {
    padding: 6px 0 7px;
    padding-left: 10px;
}

.MuiTextField-root {
    width: 100%;
}

.home-button-area {
    display: flex;
}

.form-title {
    margin-block-start: 0;
    margin-block-end: 8px;
}

.confirm-finalize-message {
    max-width: 280px;
}

.finalize-error-message {
    color: #f44336;
}

.finalize-success-message {
    color: green;
}

.finalize-result {
    padding-left: 5px;
    margin-top: 12px;
    margin-bottom: 2px;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.66;
}

.left-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.left-container .download-excel-button {
    width: 180px;
    margin-top: 20px;
}

.right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.right-container .download-excel-button {
    display: none;
}

.home-field .cf-field input{
    text-transform: uppercase;
}


@media screen and (max-width: 600px) {
    .home-content {
        display: block;
    }

    .home-area-form{
        margin: 16px;
    }

    .left-container .download-excel-button {
        display: none;
    }

    .right-container .download-excel-button {
        display: inline;
        width: 180px;
        margin-top: 20px;
    }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}