@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;900&display=swap);

.modal{position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,0.6);z-index:10}.modal-main{position:fixed;background:white;height:auto;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);display:flex;background-color:#F5F5F5;border:3px solid #7c7c7c;border-radius:5px;flex-direction:column;align-items:center;padding:1rem;padding-top:0;padding-bottom:2rem;margin:2rem}.display-block{display:block}.display-none{display:none}.modal-button-container{width:340px;display:flex;justify-content:space-around}.modal-button{width:120px}.home-button-area .reset-button{width:100px;margin-right:10px}

.home-content{display:flex;justify-content:center;align-items:flex-start;padding:20px}.home-area-form{margin:0 0 0 1rem;max-width:360px}.area-form .MuiFormHelperText-contained{margin:0;margin-top:4px}.home-field{margin-top:1rem;display:flex;width:100%}.home-field .MuiInputLabel-outlined{-webkit-transform:translate(14px, 10px) scale(1);transform:translate(14px, 10px) scale(1)}.home-field .MuiOutlinedInput-input{padding:6px 0 7px;padding-left:10px}.MuiTextField-root{width:100%}.home-button-area{display:flex}.form-title{-webkit-margin-before:0;margin-block-start:0;-webkit-margin-after:8px;margin-block-end:8px}.confirm-finalize-message{max-width:280px}.finalize-error-message{color:#f44336}.finalize-success-message{color:green}.finalize-result{padding-left:5px;margin-top:12px;margin-bottom:2px;font-size:0.75rem;font-weight:400;line-height:1.66}.left-container{display:flex;flex-direction:column;align-items:center}.left-container .download-excel-button{width:180px;margin-top:20px}.right-container{display:flex;flex-direction:column;align-items:center}.right-container .download-excel-button{display:none}.home-field .cf-field input{text-transform:uppercase}@media screen and (max-width: 600px){.home-content{display:block}.home-area-form{margin:16px}.left-container .download-excel-button{display:none}.right-container .download-excel-button{display:inline;width:180px;margin-top:20px}}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}input[type=number]{-moz-appearance:textfield}

.login-title{font-size:24px;font-weight:bold;margin-bottom:1rem}.login-field{margin-top:1rem}.MuiTextField-root{width:100%}.login-content{display:flex;flex-direction:column;align-items:center}

.modify-password-title{font-size:24px;font-weight:bold;margin-bottom:1rem}.modify-password-field{margin-top:1rem}.MuiTextField-root{width:100%}.modify-password-content{display:flex;flex-direction:column;align-items:center}.first-field{margin-top:0}.modify-password-content h4{padding-left:12px;padding-right:12px}.modify-password-field p{max-width:220px;padding-left:10px}

@font-face{font-family:"Typo Grotesk Bold";src:url(/static/media/typo_grotesk_bold_demo.45d1736c.otf)}@font-face{font-family:"Typo Grotesk";src:url(/static/media/typo_grotesk_demo.ec3f6624.otf)}:root{--primary: #004e96;--main-bg-color: #ecf0f3}html{min-height:100%}body{margin:0;min-height:100vh;display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:#ecf0f3;background-color:var(--main-bg-color);font-family:"Typo Grotesk";position:relative}.area-form{border-radius:8px;flex-direction:column;align-items:center;background:#ecf0f3;box-shadow:14px 14px 20px #cbced1, -14px -14px 20px white;padding:2rem}.area-form button{margin-top:1rem;width:100%;border-radius:50px}.MuiOutlinedInput-root{background:#ecf0f3;background:var(--main-bg-color);padding:10px;padding-left:20px;height:50px;font-size:14px;border-radius:50px !important;box-shadow:inset 6px 6px 6px #cbced1, inset -6px -6px 6px white}.overlay{z-index:100;width:100%;height:100%;position:absolute;background-color:rgba(0,0,0,0.3);top:0;left:0;display:flex;justify-content:center;align-items:center}.overlay .loading-gif{width:5rem}

